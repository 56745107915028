import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Radio,
  Checkbox,
  Select,
  Button,
  Upload,
  DatePicker,
  TimePicker,
  Slider,
  message,
  Card,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Container } from "react-bootstrap";
import { GetFormById, SubmitForm } from "../../apis/FormApi";
import { useNavigate, useParams, useLocation } from "react-router";
import "./Formcard.scss";

const { TextArea } = Input;
const { Option } = Select;

const FillForm = () => {
  const { id } = useParams();
  const location = useLocation();
  const formId = location.state?.form_id;

  const [form] = Form.useForm();
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [sliderValues, setSliderValues] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({});

  useEffect(() => {
    const fetchForm = async () => {
      if (!formId) {
        message.error("Invalid form data.");
        return;
      }

      try {
        const res = await GetFormById(formId);
        setFormData(res?.data?.data);
      } catch (error) {
        message.error("Failed to load form.");
      } finally {
        setLoading(false);
      }
    };
    fetchForm();
  }, [formId]);

  // Handle file upload
  const handleFileUpload = ({ fileList }, question) => {
    setUploadedFiles((prev) => ({
      ...prev,
      [question.question_text]: fileList.map((file) => file.originFileObj),
    }));
  };

  // Function to handle validation failure
  const onFinishFailed = (errorInfo) => {
    message.error("Please fill all required fields before submitting.");

    // Scroll to the first unfilled required field
    if (errorInfo.errorFields.length > 0) {
      const firstErrorField = document.querySelector(
        `[name="${errorInfo.errorFields[0].name}"]`
      );
      if (firstErrorField) {
        firstErrorField.scrollIntoView({ behavior: "smooth", block: "center" });
        firstErrorField.focus();
      }
    }
  };

  // Form submission
  const onFinish = async (values) => {
    // setSubmitting(true);
    const formDataToSend = new FormData();
    formDataToSend.append("user_form_id", id);

    Object.keys(values).forEach((key) => {
      const questionText = key.replace("question_", "");
      if (!uploadedFiles[questionText]) {
        formDataToSend.append(`answers[${questionText}]`, values[key]);
      }
    });

    Object.keys(uploadedFiles).forEach((key) => {
      uploadedFiles[key].forEach((file) => {
        formDataToSend.append(`answers[${key}]`, file);
      });
    });
console.log(formDataToSend,"formDataToSend")
    try {
      const response = await SubmitForm(formDataToSend);
      message.success("Form submitted successfully!");
      form.resetFields();
      setUploadedFiles({});
      navigate("/forms");
    } catch (error) {
      message.error("Failed to submit form. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  // Render input fields dynamically
  const renderQuestionField = (question) => {
    console.log(question, "question===");
    switch (question.type) {
      case "TEXT":
      case "short_answer":
        return <Input placeholder="Enter your answer" />;

      case "PARAGRAPH":
        return <TextArea rows={4} placeholder="Enter detailed answer" />;

      case "MULTIPLE_CHOICE":
        return (
          <Radio.Group>
            {JSON.parse(question.options || "[]").map((option, index) => (
              <Radio key={index} value={option}>
                {option}
              </Radio>
            ))}
          </Radio.Group>
        );

      case "CHECKBOX":
        return (
          <Checkbox.Group options={JSON.parse(question.options || "[]")} />
        );

      case "DROPDOWN":
      case "select":
        return (
          <Select placeholder="Select an option">
            {JSON.parse(question.options || "[]").map((option, index) => (
              <Option key={index} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        );

      case "DATE":
        return (
          <DatePicker
            format="YYYY-MM-DD"
            // disabledDate={(current) => current && current < new Date().setHours(0, 0, 0, 0)}
          />
        );

      case "TIME":
        return <TimePicker format="HH:mm A" minuteStep={5} />;

      // case "FILE_UPLOAD":
      //   return (
      //     <Upload
      //       listType="picture"
      //       multiple
      //       onChange={(info) => handleFileUpload(info, question)}
      //       beforeUpload={() => false}
      //     >
      //       <Button icon={<UploadOutlined />}>Upload File</Button>
      //     </Upload>
      //   );

      case "FILE_UPLOAD":
        return (
          <Upload
            listType="picture"
            multiple
            beforeUpload={(file) => {
              const isImage = file.type.startsWith("image/");
              if (!isImage) {
                message.error("Only image files (JPG, PNG, GIF) are allowed!");
              }
              return isImage || Upload.LIST_IGNORE; // Reject non-image files
            }}
            onChange={(info) => handleFileUpload(info, question)}
          >
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        );

        case "LINEAR_SCALE":
          return (
            <Form.Item
              name={`question_${question.question_text}`}
              initialValue={question?.scale_min ?? 0} // ✅ Set default initial value
            >
              <Slider
                min={question?.scale_min ?? 1}
                max={question?.scale_max ?? 10}
                step={1}
                onChange={(value) => {
                  form.setFieldsValue({ [`question_${question.question_text}`]: value }); // ✅ Update form field
                  setSliderValues((prev) => ({ ...prev, [question.id]: value })); // ✅ Update state
                }}
              />
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5px" }}>
                <span>{question?.low_label}</span>
                <span>{question?.high_label}</span>
              </div>
            </Form.Item>
          );
        

      default:
        return (
          <Input placeholder="Enter your response" className="form-input" />
        );
    }
  };

  return (
    <div className="fillform">
      <Container>
        {loading ? (
          <p>Loading...</p>
        ) : formData ? (
          <div className="form-container">
            <h2 className="form-title">{formData.name}</h2>
            <p className="form-description">{formData.description}</p>

            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed} // Handle validation errors
            >
              {formData.form_questions?.map((question) => (
                <Card key={question.id} className="form-card">
                  <Form.Item
                    name={`question_${question.question_text}`}
                    label={question.question_text}
                    rules={[
                      {
                        required:
                          question.type !== "FILE_UPLOAD" &&
                          question.is_required, // File upload is optional
                        message: "This field is required!",
                      },
                    ]}
                  >
                    {renderQuestionField(question)}
                  </Form.Item>
                </Card>
              ))}

              {formData.form_section?.map((section) => (
                <div key={section.id} className="section-container">
                  <h3 className="section-title">{section.title}</h3>
                  {section.section_questions.map((question) => (
                    <Card key={question.id} className="form-card">
                      <Form.Item
                        name={`question_${question.question_text}`}
                        label={question.question_text}
                        rules={[
                          {
                            required:
                              question.type !== "FILE_UPLOAD" &&
                              question.is_required,
                            message: "This field is required!",
                          },
                        ]}
                      >
                        {renderQuestionField(question)}
                      </Form.Item>
                    </Card>
                  ))}
                </div>
              ))}

              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={submitting}>
                  {submitting ? <Spin size="small" /> : "Submit"}
                </Button>
              </Form.Item>
            </Form>
          </div>
        ) : (
          <p>No form data available.</p>
        )}
      </Container>
    </div>
  );
};

export default FillForm;
