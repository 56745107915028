import { Button, Card, Modal } from "antd";
import React, { useState } from "react";
import "./Formcard.scss";
import SquarePaymentForForm from "../SquarePaymentForm/SquarePaymentForForm";
import { useNavigate } from "react-router";
import FormPreview from "./FormPreview";

const Formcard = ({ data, button_text }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [previewForm, setPreviewForm] = useState(null);
  const navigate = useNavigate();

  // Function to handle purchasing
  const handlePurchase = (id, amount) => {
    console.log(id, "Purchase form ID");
    setSelectedFormId(id);
    setSelectedAmount(amount);
    setIsModalOpen(true);
  };

  // Function to handle filling the form
  const handleFillForm = (id, form_id) => {
    console.log(id, form_id, "Fill Form ID");
    navigate(`/fillform/${id}`, { state: { form_id: form_id } });
  };

  // Function to handle preview before downloading
  const handlePreviewForm = (form) => {
    console.log("Previewing form:", form.id);
    setPreviewForm(form);
    setPreviewModalOpen(true);
  };

  // Close modals
  const handleCloseModal = () => setIsModalOpen(false);
  const handleClosePreviewModal = () => setPreviewModalOpen(false);

  // const daysLeft = (date) => {
  //   if (!date) return 0; // Handle undefined/null date
  //   console.log(date, "==="); // Log actual date value
  //   return Math.max(0, Math.ceil((new Date(date) - new Date()) / (1000 * 60 * 60 * 24)));
  // };
  const daysLeft = (date) => {
    if (!date) return 0;
    return Math.max(0, Math.ceil((new Date(date) - new Date()) / (1000 * 60 * 60 * 24)));
  };
  return (
    <div>
      <div className="row row-cols-1 row-cols-md-3 g-4 formCard">
        {data?.map((item, index) => {
          const accessibleDate = new Date(item.accessible_date);
          const today = new Date();
          const isAccessible = accessibleDate >= today;

          return (
            <Card hoverable key={index}>
            <div className="textlink">
              <h6 className="title">{item?.name}</h6>
              <p className="description">{item?.description}</p>
              <p className="price">${item?.price}</p>
          
              {/* Display days left */}
              {item?.accessible_date &&
              <p className="date">
                {daysLeft(item?.accessible_date) > 0
                  ? `${daysLeft(item?.accessible_date)} days Left`
                  : "Expired"}
              </p>
              }
          
              <div className="Button_Section">
                <Button
                  onClick={() =>
                    button_text === "Purchase"
                      ? handlePurchase(item.id, item?.price)
                      : handleFillForm(item.id, item.form_id)
                  }
                  disabled={item?.is_purchasable === false || item?.is_submitted === true}
                >
                  {button_text}
                </Button>
          
                {/* Disable Preview & Download if date has passed */}
                {item?.is_submitted && (
                  <Button
                    onClick={() => handlePreviewForm(item)}
                    disabled={daysLeft(item?.accessible_date) === 0}
                  >
                    Preview & Download
                  </Button>
                )}
              </div>
            </div>
          </Card>
          );
        })}
      </div>

      {/* Payment Modal */}
      <Modal
        title="Complete Your Payment"
        open={isModalOpen}
        onCancel={handleCloseModal}
        footer={null}
        centered
      >
        {selectedFormId && (
          <SquarePaymentForForm
            formId={String(selectedFormId)}
            amount={selectedAmount} // Pass amount to SquarePaymentForForm
            onClose={handleCloseModal}
          />
        )}
      </Modal>

      {/* Form Preview Modal */}
      <Modal
        title="Form Preview"
        open={previewModalOpen}
        onCancel={handleClosePreviewModal}
        footer={null}
        centered
        width={800}
      >
        {previewForm && (
          <FormPreview
            formId={previewForm.id}
            accessibleDate={previewForm.accessible_date}
          />
        )}
      </Modal>
    </div>
  );
};

export default Formcard;
