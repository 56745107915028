import React, { useEffect, useState } from "react";
import { Table, message, Button, Modal, Space, Select } from "antd";
import { DeleteShareData, GetCompanyName, PostShareData, SharedDataList } from "../../apis/Api";
import "./SharedData.scss";

const SharedData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    hanldeShareList();
  }, []);

  const hanldeShareList = () => {
    setLoading(true);
    SharedDataList()
      .then((res) => {
        if (res.data.code === 200) {
          const formattedData = res.data.data.map((item) => ({
            ...item,
            ...item.corporate_shared_data,
            id: item.id,
          }));
          setData(formattedData);
        } else {
          message.error("Failed to fetch data");
        }
      })
      .catch(() => {
        message.error("An error occurred while fetching data");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    GetCompanyName()
      .then((res) => {
        if (res.data && res.data.data) {
          setCompanies(res.data.data);
        }
      })
      .catch(() => {
        message.error("Failed to fetch companies");
      });
  }, []);

  const handleDelete = (id) => {
    const formData = {
      corporate_id: selectedRowKeys.length === 0 ? [id] : selectedRowKeys,
    };
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure you want to delete this data?",
      onOk: () => {
        DeleteShareData(formData)
          .then(() => {
            message.success("Data deleted successfully");
            hanldeShareList();
            setData(data.filter((item) => item.id !== id));
            setSelectedRowKeys(selectedRowKeys.filter((key) => key !== id));
          })
          .catch(() => {
            message.error("Failed to delete data");
          });
      },
    });
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Company Name", dataIndex: "company_name", key: "company_name" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button danger onClick={() => handleDelete(record.id)} disabled={selectedRowKeys.length > 1}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys),
  };

  const handleShareData = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (selectedCompany) {
      PostShareData(selectedCompany.id)
        .then(() => {
          message.success(`Data shared with ${selectedCompany.company_name}`);
          hanldeShareList();
        })
        .catch(() => {
          message.error("Failed to share data");
        });
      setIsModalVisible(false);
    } else {
      message.error("No company selected");
    }
  };

  return (
    <div>
      <div className="page-header">
        <div>
          <h3>Shared Data</h3>
          <p>View and manage the shared data information below</p>
          <Button type="primary" danger onClick={() => handleDelete()} disabled={selectedRowKeys.length === 0}>
            Delete Selected
          </Button>
        </div>
        <div>
          <Button onClick={handleShareData}
          className="shareData"
          >
            Share Data
          </Button>
        </div>
      </div>
      <Table rowSelection={rowSelection} columns={columns} dataSource={data} loading={loading} rowKey="id" pagination={{ pageSize: 10 }} />
      <Modal title="Select Company to Share Data" visible={isModalVisible} onOk={handleOk} onCancel={() => setIsModalVisible(false)} okText="Share" cancelText="Cancel">
        <p>Select a company to share your data with:</p>
        <Select
          style={{ width: "100%" }}
          placeholder="Select Company"
          onChange={(value) => setSelectedCompany(companies.find((c) => c.id === value))}
        >
          {companies.map((company) => (
            <Select.Option key={company.id} value={company.id}>
              {company.company_name}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

export default SharedData;
