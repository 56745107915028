import React, { useEffect, useState } from "react";
import { GetFillFormById } from "../../apis/FormApi";
import { Button, message, Spin } from "antd";
import { Container } from "react-bootstrap";
import "./FormPreview.scss";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const BASE_URL = "https://node.nucleargauges.com:5000/form_uploads/";

const formatValue = (value) => (value === undefined || value === "undefined" ? "--" : value);

// const pdfStyles = StyleSheet.create({
//   page: {
//     flexDirection: "column",
//     backgroundColor: "#FFFFFF",
//     padding: 30,
//     paddingLeft: 50,
//     paddingRight: 50,
//     alignItems: "center",
//   },
//   formContainer: {
//     width: "100%",
//     maxWidth: 700,
//     backgroundColor: "#fff",
//     borderRadius: 8,
//   },
//   sectionTitle: {
//     marginBottom: 20,
//     marginTop: 20,
//     fontSize: 16,
//     fontWeight: "bold",
//   },
//   responseItem: {
//     paddingTop: 5,
//     paddingBottom: 5,
//   },
//   question: {
//     color: "#333",
//     marginBottom: 5,
//     fontSize: 11,
//     fontWeight: "bold",
//   },
//   answer: {
//     fontSize: 11,
//     color: "#555",
//   },
//   imageSection: {
//     width: 100,
//     height: 100,
//     marginTop: 10,
//     // borderRadius: 50,
//     overflow: "hidden",
//     border: "1px solid #ccc",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   image: {
//     width: "100%",
//     height: "100%",
//     objectFit: "cover",
//   },
// });

const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 30,
    alignItems: "center",
    margin: 40, // Ensures uniform margins on all pages
  },
  formContainer: {
    width: "100%",
    maxWidth: 700,
    backgroundColor: "#fff",
    borderRadius: 8,
  },
  sectionTitle: {
    marginBottom: 20,
    marginTop: 20,
    fontSize: 16,
    fontWeight: "bold",
  },
  responseItem: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  question: {
    color: "#333",
    marginBottom: 5,
    fontSize: 11,
    fontWeight: "bold",
  },
  answer: {
    fontSize: 11,
    color: "#555",
  },
  imageSection: {
    width: 100,
    height: 100,
    marginTop: 10,
    overflow: "hidden",
    border: "1px solid #ccc",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
});

const FormPreview = ({ formId }) => {
  const [loading, setLoading] = useState(true);
  const [formResponses, setFormResponses] = useState([]);

  useEffect(() => {
    if (!formId) {
      message.error("Invalid Form ID");
      return;
    }

    const fetchFormResponses = async () => {
      try {
        const res = await GetFillFormById(formId);
        if (res?.data?.success) {
          setFormResponses(res?.data?.data || []);
        } else {
          message.error("Failed to retrieve form responses.");
        }
      } catch (error) {
        console.error("Error fetching form responses:", error);
        message.error("Error loading form preview.");
      } finally {
        setLoading(false);
      }
    };

    fetchFormResponses();
  }, [formId]);

  const MyDocument = ({ responses }) => (
    <Document>
      <Page style={pdfStyles.page}>
        <View style={pdfStyles.formContainer}>
          {responses.length > 0 ? (
            responses.map((section, sectionIndex) => (
              <View key={sectionIndex} style={pdfStyles.section}>
                {/* Section Title */}
                <Text style={pdfStyles.sectionTitle}>
                  {formatValue(section?.section_title)}
                </Text>

                {/* Check if section has questions */}
                {Array.isArray(section?.questions) && section.questions.length > 0 ? (
                  section.questions.map((response, index) => {
                    const isImage =
                      response?.answer &&
                      typeof response.answer === "string" &&
                      response.answer.includes("/form_uploads/");
                    const imageUrl = isImage
                      ? response.answer.startsWith("http")
                        ? response.answer
                        : `${BASE_URL}${encodeURIComponent(
                            response.answer.replace("/form_uploads/", "")
                          )}`
                      : null;

                    return (
                      <View key={index} style={pdfStyles.responseItem}>
                        {/* Ensure question prints */}
                        <Text style={pdfStyles.question}>
                          {formatValue(response?.question_text)}
                        </Text>

                        {/* Print answer or image */}
                        {isImage ? (
                          <View style={pdfStyles.imageSection}>
                            <Image style={pdfStyles.image} src={imageUrl} />
                          </View>
                        ) : (
                          <Text style={pdfStyles.answer}>
                            {formatValue(response?.answer)}
                          </Text>
                        )}
                      </View>
                    );
                  })
                ) : (
                  <Text style={pdfStyles.answer}>No questions available</Text>
                )}
              </View>
            ))
          ) : (
            <Text>No responses found.</Text>
          )}
        </View>
      </Page>
    </Document>
  );

  return (
    <div className="form-preview">
      <Container>
        {loading ? (
          <Spin size="large" />
        ) : formResponses.length > 0 ? (
          <>
            {formResponses.map((section, sectionIndex) => (
              <div key={sectionIndex} className="form-container">
                <h4 className="section-title">{formatValue(section?.section_title)}</h4>
                {Array.isArray(section?.questions) && section.questions.length > 0 ? (
                  section.questions.map((response, index) => {
                    const isImage =
                      response?.answer &&
                      typeof response.answer === "string" &&
                      response.answer.includes("/form_uploads/");
                    const imageUrl = isImage
                      ? response.answer.startsWith("http")
                        ? response.answer
                        : `${BASE_URL}${encodeURIComponent(
                            response.answer.replace("/form_uploads/", "")
                          )}`
                      : null;

                    return (
                      <div key={index} className="response-item">
                        <div className="question">
                          <strong>{formatValue(response?.question_text)}</strong>
                        </div>
                        {isImage ? (
                          <div className="image-section">
                            <img
                              src={imageUrl}
                              alt="Uploaded Proof"
                              className="uploaded-image"
                              crossOrigin="anonymous"
                            />
                          </div>
                        ) : (
                          <div className="answer">{formatValue(response?.answer)}</div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p>No questions available</p>
                )}
              </div>
            ))}
            <div className="button-group">
              <PDFDownloadLink
                document={<MyDocument responses={formResponses} />}
                fileName="form_responses.pdf"
              >
                {({ loading }) => (
                  <Button type="default" disabled={loading}>
                    {loading ? <Spin size="small" /> : "Download PDF"}
                  </Button>
                )}
              </PDFDownloadLink>
            </div>
          </>
        ) : (
          <p>No responses found for this form.</p>
        )}
      </Container>
    </div>
  );
};

export default FormPreview;
