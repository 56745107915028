import apiClient from "./apiClient";

export const GetAllForms = async () => {
  return await apiClient.get(`form/getAllForms`);
};

export const GetAllPayedForms = async () => {
  return await apiClient.get(`form/getAllPayedForms`);
};

export const GetFormById = async (form_id) => {
  return await apiClient.get(`form/findFormById/${form_id}`);
};

export const SubmitForm = async (formData) => {
  return await apiClient.post(`form/submitForm`, formData);
};

export const GetFillFormById = async (id) => {
  return await apiClient.get(`form/getSubmittedAnswer/${id}`);
};
