import React, { useEffect, useMemo, useState } from "react";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import { message, Spin } from "antd";
import Loader from "../Loader/Loader";
import "./SquarePaymentForm.scss";
import { SquareKey } from "../../apis/Api";
import { useNavigate } from "react-router";
import { getLocalStorageItem } from "../../apis/locastorageHandle";

const SquarePaymentForForm = ({ formId, amount, onClose }) => {
  const [squareAppId, setSquareAppId] = useState("");
  const [squareLocationId, setSquareLocationId] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const token = getLocalStorageItem("userToken");
  const navigate = useNavigate();

  const headers = useMemo(
    () => ({
      "Content-Type": "application/json",
      "x-access-token": token,
    }),
    [token]
  );

  const handlePayment = async (token, verificationToken) => {
    setIsLoading(true); // Show spinner on button click
    try {
      const body = JSON.stringify({
        locationId: squareLocationId,
        sourceId: token.token,
        verificationToken,
        idempotencyKey: window.crypto.randomUUID(),
        form_id: formId,
      });

      const response = await fetch(
        `https://node.nucleargauges.com:5000/api/v1/payment/form/square/create-payment`,
        {
          method: "POST",
          headers,
          body,
        }
      );

      if (response.ok) {
        const paymentData = await response.json();

        if (paymentData?.data?.status === "COMPLETED") {
          message.success("Payment successful! Thank you for your purchase.");
          onClose();
          navigate(`/forms`);
        }

        // Close modal and navigate to the form
        onClose();
        navigate(`/forms`);
      } else {
        console.error("Payment failed:", await response.text());
      }
    } catch (error) {
      console.error("Error processing payment:", error);
    } finally {
      setIsLoading(false); // Hide spinner after request completes
    }
  };

  // Fetch Square keys
  useEffect(() => {
    const fetchSquareKeys = async () => {
      try {
        const response = await SquareKey(headers);
        const { squareAppId, squareLocationId } = response.data.data;
        setSquareAppId(squareAppId);
        setSquareLocationId(squareLocationId);
      } catch (error) {
        console.error("Error fetching Square keys:", error);
      }
    };

    fetchSquareKeys();
  }, [headers]);

  if (!squareAppId || !squareLocationId) {
    return (
      <div className="paymentLoader">
        <Loader />
        <p>Loading payment form...</p>
      </div>
    );
  }

  return (
    <div className="square-payment-form">
      <PaymentForm
        applicationId={squareAppId}
        locationId={squareLocationId}
        cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
          await handlePayment(token, verifiedBuyer);
        }}
      >
        <CreditCard
          buttonProps={{
            css: {
              backgroundColor: isLoading ? "#ccc" : "#771520",
              fontSize: "14px",
              color: "#fff",
              cursor: isLoading ? "not-allowed" : "pointer",
              "&:hover": {
                backgroundColor: isLoading ? "#ccc" : "#530f16",
              },
            },
            disabled: isLoading, // Disable button while loading
          }}
        >
          {isLoading ? <Spin size="small" /> : `Pay $${amount}`}{" "}
          {/* Show spinner while loading */}
        </CreditCard>
      </PaymentForm>
    </div>
  );
};

export default SquarePaymentForForm;
