import React, { useEffect, useState } from "react";
import { ListGroup, Button, Spinner } from "react-bootstrap";
import {
  AcceptShareRequestApi,
  AllRequestForshareDataForUser,
} from "../../apis/Api";
import { message } from "antd";

const Notification = ({ onCancel }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = () => {
    setLoading(true);
    AllRequestForshareDataForUser()
      .then((res) => {
        setNotifications(res?.data?.data || []);
      })
      .catch((e) => console.log(e, "===e"))
      .finally(() => setLoading(false));
  };

  const handleAccept = (id) => {
    const formData = {
      requestId: id,
    };
    AcceptShareRequestApi(formData)
      .then((res) => {
        console.log(res, "res==data accept");
        message.success(res?.data?.message);
        fetchNotifications();
        onCancel();
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  return (
    <div>
      {loading ? (
        <Spinner animation="border" />
      ) : notifications.length > 0 ? (
        <ListGroup>
          {notifications.map((notification, index) => (
            <ListGroup.Item
              key={index}
              className="d-flex justify-content-between align-items-center"
            >
              <div>

              <div>
                <span>
                  Company&nbsp;
                  <b>
                    {notification?.corporate_shared_data_request?.company_name}
                  </b>
                  &nbsp;requests access to your data for a better experience.
                </span>
              </div>
              <div>
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => handleAccept(notification.id)}
                  >
                  Accept
                </Button>{" "}
                <Button variant="secondary" size="sm" onClick={onCancel}>
                  Ignore
                </Button>
              </div>
                  </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <p>No new notifications</p>
      )}
    </div>
  );
};

export default Notification;
