import React, { useState } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { FaShoppingCart, FaBell } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Space, Modal } from "antd";
import { useSelector } from "react-redux";
import logo from "../../assets/Logo.png";
import CustomButton from "../CustomButton/CustomButton";
import "./NavigationBar.scss";
import { getLocalStorageItem } from "../../apis/locastorageHandle";
import Notification from "../Notification/Notification";

const NavigationBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [modalOpen, setModalOpen] = useState(false);
  const token = getLocalStorageItem("userToken");
  const userType = getLocalStorageItem("myValue");

  const { cart } = useSelector((state) => state.cartReducer);
  const navigate = useNavigate();

  const handleLinkClick = (linkName) => setActiveLink(linkName);
  const handleNavigate = (path) => navigate(path);

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("myValue");
    navigate("/");
  };

  return (
    <>
      <Navbar expand="lg" className="navbar">
        <Container>
          <Navbar.Brand onClick={() => handleNavigate("/")}>
            <img src={logo} width="150" className="d-inline-block align-top" alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link
                className={activeLink === "home" ? "active" : ""}
                onClick={() => {
                  handleLinkClick("home");
                  handleNavigate("/");
                }}
              >
                Home
              </Nav.Link>
              {token && (
                <>
                  <Nav.Link
                    className={activeLink === "dashboard" ? "active" : ""}
                    onClick={() => {
                      handleLinkClick("dashboard");
                      handleNavigate("/dashboard");
                    }}
                  >
                    Dashboard
                  </Nav.Link>
                  <Nav.Link
                    className={activeLink === "shop" ? "active" : ""}
                    onClick={() => {
                      handleLinkClick("shop");
                      handleNavigate("/courses");
                    }}
                  >
                    Shop
                  </Nav.Link>
                  <Nav.Link
                    className={activeLink === "forms" ? "active" : ""}
                    onClick={() => {
                      handleLinkClick("forms");
                      handleNavigate("/forms");
                    }}
                  >
                    Forms
                  </Nav.Link>
                </>
              )}
              <Nav.Link
                className={activeLink === "contact" ? "active" : ""}
                onClick={() => {
                  handleLinkClick("contact");
                  handleNavigate("/contact-us");
                }}
              >
                Contact Us
              </Nav.Link>

              {/* ✅ Notification Button */}
              {token && (
                <Nav.Link className="notification-icon" onClick={() => setModalOpen(true)}>
                  <Space>
                    <Badge count={0} showZero>
                      <FaBell size={20} />
                    </Badge>
                  </Space>
                </Nav.Link>
              )}

              {/* ✅ Shopping Cart */}
              {token && userType !== "7" && (
                <Nav.Link className={activeLink === "shoppingcart" ? "active" : ""} onClick={() => handleNavigate("/shopingcart")}>
                  <Space>
                    <Badge count={cart?.cartItems?.length || 0} showZero>
                      <FaShoppingCart size={20} />
                    </Badge>
                  </Space>
                </Nav.Link>
              )}

              {/* ✅ Account Dropdown */}
              {token ? (
                <NavDropdown title="Account" id="basic-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/profile" onClick={() => handleLinkClick("profile")}>
                    Profile
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
              ) : (
                <CustomButton text="Register / Login" onClick={() => handleNavigate("/login")} />
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* ✅ Notification Modal */}
      <Modal title="Notifications" open={modalOpen} onCancel={() => setModalOpen(false)} footer={null} width="50vw">
        <Notification onCancel={() => setModalOpen(false)} />
      </Modal>
    </>
  );
};

export default NavigationBar;
