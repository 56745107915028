import React, { useEffect, useState } from "react";
import { Tabs, Skeleton } from "antd";
import { GetAllForms, GetAllPayedForms } from "../../apis/FormApi";
import Formcard from "./Formcard";
import { Container } from "react-bootstrap";

const { TabPane } = Tabs;

const FormsComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [hasFetchedPurchasedForms, setHasFetchedPurchasedForms] =
    useState(false);

  // ✅ Fetch forms based on the tab key
  const fetchForms = async (tabKey, forceUpdate = false) => {
    setLoading(true);
    try {
      let response;
      if (tabKey === "1") {
        response = await GetAllForms();
      } else if (tabKey === "2" && (!hasFetchedPurchasedForms || forceUpdate)) {
        response = await GetAllPayedForms();
        setHasFetchedPurchasedForms(true);
      }
      setData(response?.data?.data || []);
    } catch (error) {
      console.error(error, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchForms(activeTab);
  }, [activeTab]);

  // ✅ Callback function to refresh Purchased Forms when a form is purchased
  const handlePurchase = () => {
    if (activeTab === "2") {
      fetchForms("2", true); // Force refresh
    }
  };

  return (
    <div className="FormsComponent">
      <Container>
        <Tabs
          activeKey={activeTab}
          onChange={(key) => {
            setActiveTab(key);
            fetchForms(key, true); // ✅ Always refresh on tab change
          }}
        >
          <TabPane tab="All Forms" key="1">
            <div className="section">
              {loading ? (
                <Skeleton active />
              ) : (
                <Formcard
                  data={data}
                  loading={loading}
                  button_text="Purchase"
                  onPurchase={handlePurchase} // ✅ Pass refresh function to Formcard
                />
              )}
            </div>
          </TabPane>
          <TabPane tab="Purchased Forms" key="2">
            <div className="section">
              {loading ? (
                <Skeleton active />
              ) : (
                <Formcard
                  data={data}
                  loading={loading}
                  button_text="Fill Form"
                />
              )}
            </div>
          </TabPane>
        </Tabs>
      </Container>
    </div>
  );
};

export default FormsComponent;
