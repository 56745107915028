import React from "react";
import WebRouter from "./WebRouter";
// import NavBar from "./Components/NavBar/NavBar";
import NavigationBar from "./Components/NavigationBar/NavigationBar";
import Footer from "./Components/Footer/Footer";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "./App.css";
import { useLocation } from "react-router";


function App() {
  const location = useLocation();
  const hideNavAndFooter = ["/login", "/signup","/forgotpassword","/email-verify"].includes(location.pathname);
  const hideFooter = location.pathname.startsWith("/courseongoing");
  return (
    <Provider store={store}>
      <div className="app-container">
      {!hideNavAndFooter && <NavigationBar />}
        <div className="content">
          <WebRouter /> 
         
        </div>
        {!hideFooter && !hideNavAndFooter && <Footer />}
      </div>
    </Provider>
  );
}

export default App;
