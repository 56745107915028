import React from 'react'
import { Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import logo from "../../assets/Logo.png"
import "./Footer.scss"
const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate()
  const handleNaviagte = (key)=>{
   navigate(key)
  }
  return (
    <div  className="footer">
    <Container>
  <div className="footerTop">
      <img src={logo} onClick={()=>handleNaviagte("/")} alt="Nuclear Gauges USA" className="logo" />
      <nav className="nav">
          <Link to="/">Home</Link>
          <Link to="/forum">Forum</Link>
          <Link to="/contact-us">Contact Us</Link>
      </nav>
  </div>
  <div className="footerBottom">
      <p>Copyright © {currentYear} NUCLEAR GAUGES</p>
      <div className="links">
          <Link href="/privacy-policy">Privacy Policy</Link>
          <Link href="/terms-conditions">Terms & Conditions</Link>
      </div>
  </div>
  </Container>
</div>
  )
}

export default Footer